'use strict';

var cov_1wrx9w5ne4 = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/program/program.js',
        hash = 'd57074ac8ff8b67f1009e49f21e78146784f9085',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/program/program.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 27
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 36
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 40
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 0
                },
                end: {
                    line: 20,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 49
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 9,
                        column: 62
                    },
                    end: {
                        line: 9,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 74
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProgramController = function () {
    function ProgramController($stateParams, $rootScope, $http) {
        _classCallCheck(this, ProgramController);

        cov_1wrx9w5ne4.f[0]++;
        cov_1wrx9w5ne4.s[0]++;

        this.$http = $http;
        cov_1wrx9w5ne4.s[1]++;
        this.lang = $rootScope.lang;
    }

    _createClass(ProgramController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_1wrx9w5ne4.f[1]++;
            cov_1wrx9w5ne4.s[2]++;

            this.$http.get('events_' + this.lang + '.json').then(function (response) {
                cov_1wrx9w5ne4.f[2]++;
                cov_1wrx9w5ne4.s[3]++;

                _this.events = response.data;
            });
        }
    }]);

    return ProgramController;
}();

cov_1wrx9w5ne4.s[4]++;


angular.module('app').component('program', {
    templateUrl: 'app/components/program/program.html',
    controller: ProgramController
});