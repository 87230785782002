'use strict';

var cov_1vx16w5aaa = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/home/home.js',
        hash = '5406ae5a6f537cdd11ad43e22c3d4fad226bf2ea',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/home/home.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 16
                },
                end: {
                    line: 4,
                    column: 17
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 28
                },
                end: {
                    line: 20,
                    column: 9
                }
            },
            '2': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 34
                }
            },
            '3': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 16
                }
            },
            '5': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 27,
                    column: 16
                },
                end: {
                    line: 27,
                    column: 22
                }
            },
            '7': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 34,
                    column: 0
                },
                end: {
                    line: 39,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 27
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 24,
                        column: 18
                    },
                    end: {
                        line: 24,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 24
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                },
                line: 24
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 26,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 26,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                }, {
                    start: {
                        line: 26,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                }],
                line: 26
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HomeController = function HomeController($interval) {
    var _this = this;

    _classCallCheck(this, HomeController);

    cov_1vx16w5aaa.f[0]++;

    var i = (cov_1vx16w5aaa.s[0]++, 0);
    var backgrounds = (cov_1vx16w5aaa.s[1]++, ['../assets/home/3d-hmb-saolazaro.jpg', '../assets/home/le_fin_demain-zirkus_morsa-poveiros.jpg', '../assets/home/six_pieds_sur_terre-lapsus_coliseu.jpg', '../assets/home/sol_bemol-dirque_et_fien-santo_ildefonso.jpg', '../assets/home/viva_victor-krak-poveiros.jpg', '../assets/home/bruit_de_couloir-clement_dazin-batalha.jpg', '../assets/home/calcada.jpg', '../assets/home/chemins.jpg', '../assets/home/ducreux.jpg', '../assets/home/marimbondo.jpg', '../assets/home/mavara.jpg', '../assets/home/nicolas_joubard.jpg', '../assets/home/rouge.jpg', '../assets/home/the_rat_pack.jpg']);

    cov_1vx16w5aaa.s[2]++;
    this.img = backgrounds[0];

    cov_1vx16w5aaa.s[3]++;
    $interval(function () {
        cov_1vx16w5aaa.f[1]++;
        cov_1vx16w5aaa.s[4]++;

        i++;
        cov_1vx16w5aaa.s[5]++;
        if (i === backgrounds.length) {
            cov_1vx16w5aaa.b[0][0]++;
            cov_1vx16w5aaa.s[6]++;

            i = 0;
        } else {
            cov_1vx16w5aaa.b[0][1]++;
        }
        cov_1vx16w5aaa.s[7]++;
        _this.img = backgrounds[i];
    }, 1000 * 5);
};

cov_1vx16w5aaa.s[8]++;


angular.module('app').component('home', {
    templateUrl: 'app/components/home/home.html',
    controller: HomeController
});