'use strict';

var cov_j7kl5ck85 = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/routes.js',
        hash = 'e1de5a7f7d541f981a563e15e67c43bf11b2a045',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 42,
                    column: 11
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 43,
                        column: 1
                    }
                },
                line: 6
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_j7kl5ck85.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_j7kl5ck85.f[0]++;
    cov_j7kl5ck85.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_j7kl5ck85.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_j7kl5ck85.s[3]++;
    $stateProvider.state('home', {
        url: '/',
        template: '<home/>'
    }).state('program', {
        url: '/programa',
        template: '<program/>'
    }).state('companies', {
        url: '/companhias',
        template: '<companies/>'
    }).state('company', {
        url: '/companhia/:slug',
        template: '<company/>'
    }).state('places', {
        url: '/locais',
        template: '<places/>'
    }).state('project', {
        url: '/projeto',
        template: '<project/>'
    }).state('press', {
        url: '/imprensa',
        template: '<press/>'
    }).state('contacts', {
        url: '/contactos',
        template: '<contacts/>'
    });
}