'use strict';

var cov_11951egp6k = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/company/company.js',
        hash = 'f1ceda8b6bf56f1e5c0095e8b946a5757f125468',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/company/company.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 27
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 36
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 32
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 15
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 17,
                    column: 17
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 14,
                    column: 42
                }
            },
            '8': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 15
                }
            },
            '10': {
                start: {
                    line: 23,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 17
                }
            },
            '11': {
                start: {
                    line: 24,
                    column: 20
                },
                end: {
                    line: 24,
                    column: 52
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 0
                },
                end: {
                    line: 37,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 49
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 65
                    },
                    end: {
                        line: 11,
                        column: 66
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 77
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 12,
                        column: 34
                    },
                    end: {
                        line: 12,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 44
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                },
                line: 12
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 62
                    },
                    end: {
                        line: 21,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 74
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                line: 21
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 22,
                        column: 34
                    },
                    end: {
                        line: 22,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 44
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                },
                line: 22
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                }, {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                }],
                line: 13
            },
            '1': {
                loc: {
                    start: {
                        line: 23,
                        column: 16
                    },
                    end: {
                        line: 25,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 23,
                        column: 16
                    },
                    end: {
                        line: 25,
                        column: 17
                    }
                }, {
                    start: {
                        line: 23,
                        column: 16
                    },
                    end: {
                        line: 25,
                        column: 17
                    }
                }],
                line: 23
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CompanyController = function () {
    function CompanyController($stateParams, $rootScope, $http) {
        _classCallCheck(this, CompanyController);

        cov_11951egp6k.f[0]++;
        cov_11951egp6k.s[0]++;

        this.slug = $stateParams.slug;
        cov_11951egp6k.s[1]++;
        this.$http = $http;
        cov_11951egp6k.s[2]++;
        this.lang = $rootScope.lang;
        cov_11951egp6k.s[3]++;
        this.presentations = [];
    }

    _createClass(CompanyController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_11951egp6k.f[1]++;
            cov_11951egp6k.s[4]++;

            this.$http.get('companies_' + this.lang + '.json').then(function (response) {
                cov_11951egp6k.f[2]++;
                cov_11951egp6k.s[5]++;

                response.data.forEach(function (record) {
                    cov_11951egp6k.f[3]++;
                    cov_11951egp6k.s[6]++;

                    if (record.slug === _this.slug) {
                        cov_11951egp6k.b[0][0]++;
                        cov_11951egp6k.s[7]++;

                        _this.company = record;
                        //this.company.presentation = record.presentation[this.lang];
                        // this.company.synopsis = record.synopsis[this.lang];
                    } else {
                        cov_11951egp6k.b[0][1]++;
                    }
                });
            });

            cov_11951egp6k.s[8]++;
            this.$http.get('events_' + this.lang + '.json').then(function (response) {
                cov_11951egp6k.f[4]++;
                cov_11951egp6k.s[9]++;

                response.data.forEach(function (record) {
                    cov_11951egp6k.f[5]++;
                    cov_11951egp6k.s[10]++;

                    if (record.slug === _this.slug) {
                        cov_11951egp6k.b[1][0]++;
                        cov_11951egp6k.s[11]++;

                        _this.presentations.push(record);
                    } else {
                        cov_11951egp6k.b[1][1]++;
                    }
                });
            });
        }
    }]);

    return CompanyController;
}();

cov_11951egp6k.s[12]++;


angular.module('app').component('company', {
    templateUrl: 'app/components/company/company.html',
    controller: CompanyController
});