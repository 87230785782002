'use strict';

var cov_pijmvus1o = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/feed/feed.js',
        hash = 'd1f4b086d380564e68835a283fbfa02c035ce337',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/feed/feed.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 11
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 13
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 16
                },
                end: {
                    line: 6,
                    column: 31
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 16
                },
                end: {
                    line: 8,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 79,
                    column: 10
                }
            },
            '5': {
                start: {
                    line: 83,
                    column: 0
                },
                end: {
                    line: 88,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 34
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 4,
                        column: 22
                    },
                    end: {
                        line: 4,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 4,
                        column: 28
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                },
                line: 4
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 13
                    }
                }, {
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 13
                    }
                }],
                line: 5
            },
            '1': {
                loc: {
                    start: {
                        line: 5,
                        column: 16
                    },
                    end: {
                        line: 5,
                        column: 48
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 5,
                        column: 16
                    },
                    end: {
                        line: 5,
                        column: 30
                    }
                }, {
                    start: {
                        line: 5,
                        column: 34
                    },
                    end: {
                        line: 5,
                        column: 48
                    }
                }],
                line: 5
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FeedController = function FeedController($mdMedia, $scope) {
    var _this = this;

    _classCallCheck(this, FeedController);

    cov_pijmvus1o.f[0]++;
    cov_pijmvus1o.s[0]++;

    $scope.$watch(function () {
        cov_pijmvus1o.f[1]++;
        cov_pijmvus1o.s[1]++;

        if ((cov_pijmvus1o.b[1][0]++, $mdMedia('sm')) || (cov_pijmvus1o.b[1][1]++, $mdMedia('md'))) {
            cov_pijmvus1o.b[0][0]++;
            cov_pijmvus1o.s[2]++;

            _this.limit = 2;
        } else {
            cov_pijmvus1o.b[0][1]++;
            cov_pijmvus1o.s[3]++;

            _this.limit = 3;
        }
    });

    cov_pijmvus1o.s[4]++;
    this.items = [{
        text: 'Os primeiros dias do Festival Internacional de Circo do Porto foram um malabarismo de emoções. Hoje, este evento dedicado ao circo contemporâneo despede-se do público, do Coliseu e da cidade. Mas ainda há muitos e bons espetáculos para ver esta tarde.',
        date: '16 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/291177795044079/'
    }, {
        text: 'Depois do maravilhoso "Speakeasy", este sábado às 21h30, e domingo às 17h30, o Coliseu recebe o espetáculo "Six Pieds Sur Terre", da companhia Lapsus.',
        date: '15 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/290651391763386/'
    }, {
        text: 'Gostava de fazer uma visita guiada ao Coliseu? A propósito do Festival Internacional de Circo do Porto, a casa do Circo por excelência da cidade do Porto vai estar a fazer visitas guiadas gratuitas.',
        date: '14 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/289937801834745/'
    }, {
        text: 'Começa hoje o I Festival Internacional de Circo do Porto! Estes são os espetáculos gratuitos que pode ver esta quinta-feira.',
        date: '13 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/289410241887501/'
    }, {
        text: 'As companhias The Rat Pack e d\'irque & fien já chegaram ao Coliseu! Atuam já esta quinta-feira, no primeiro dia do FIC Porto.',
        date: '12 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/289026158592576/'
    }, {
        text: 'Os nossos programas, com sinopses, horários e palcos, já estão espalhados pelo Coliseu e por vários espaços da cidade. Agarre o seu.',
        date: '12 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/288930861935439/'
    }, {
        text: 'Depois de já ter revelado 8 companhias, o Coliseu Porto Ageas anuncia agora o programa completo! Ao todo, vão passar pelo I Festival Internacional de Circo do Porto 13 companhias, 44 espetáculos, uma conferência internacional e apresentações diárias do Circo Social.',
        date: '06 Setembro',
        link: 'https://www.facebook.com/events/190206221807904/permalink/285428265619032/'
    }, {
        text: 'O Jornal de Notícias viu, em primeira mão, um ensaio do espetáculo que o Circo Social vai estrear no Festival Internacional de Circo do Porto. Hoje há uma Aula Aberta, às 22h00.',
        date: '31 Agosto',
        link: 'https://www.facebook.com/events/190206221807904/permalink/281876042640921/'
    }, {
        text: 'O Circo Social vai fazer uma Aula Aberta esta sexta-feira, 31 de agosto, às 22h00, junto à Igreja do Bonfim. O convite foi feito pela Junta de Freguesia do Bonfim, a propósito das Festas de Santa Clara.',
        date: '29 Agosto',
        link: 'https://www.facebook.com/events/190206221807904/permalink/280647676097091/'
    }, {
        text: 'Falta menos de um mês para o início do I Festival Internacional de Circo do Porto. Uma das 13 companhias que poderemos ver chega de França, chama-se Lapsus e é fruto da amizade de seis artistas que frequentaram juntos a École de Cirque de Lyon.',
        date: '28 Agosto',
        link: 'https://www.facebook.com/events/190206221807904/permalink/280169202811605/'
    }, {
        text: 'Falta um mês para vermos o Novo Circo nas ruas do Porto e no Coliseu! A STCP - Sociedade de Transportes Colectivos do Porto, SA e a CP - Comboios de Portugal são parceiros de divulgação e darão toda a informação sobre a melhor forma de chegar aos espetáculos.',
        date: '14 Agosto',
        link: 'https://www.facebook.com/events/190206221807904/permalink/266081427553716/'
    }, {
        text: 'Para além dos espetáculos espalhados pela cidade, o Festival vai apresentar mais duas componentes importantes. Uma delas será a Conferência Internacional, um espaço de discussão e literacia para o novo circo e o estado da arte em Portugal e na Europa.',
        date: '31 Julho',
        link: 'https://www.facebook.com/events/190206221807904/permalink/249881092507083/'
    }, {
        text: 'Faltam precisamente dois meses para o início do Festival Internacional de Circo do Porto. E faltam apenas alguns dias para divulgarmos mais um nome que vai fazer parte  do cartaz!',
        date: '13 Julho',
        link: 'https://www.facebook.com/events/190206221807904/permalink/229798561182003/'
    }];
};

cov_pijmvus1o.s[5]++;


angular.module('app').component('feed', {
    templateUrl: 'app/components/feed/feed.html',
    controller: FeedController
});