'use strict';

var cov_2k8u20askg = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/mobileHeader/mobileHeader.js',
        hash = 'f0649feb5086bbcde831ac4f29515a153433d397',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/mobileHeader/mobileHeader.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2k8u20askg.s[0]++;
angular.module('app').component('mobileHeader', {
    templateUrl: 'app/components/mobileHeader/mobileHeader.html',
    bindings: {
        theme: '<'
    }
});