'use strict';

var cov_xilwg9b9j = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/components/press/press.js',
        hash = '103d2c09c87165974b0fd95e5503b524b4c2648e',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/components/press/press.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 97,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 101,
                    column: 0
                },
                end: {
                    line: 106,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 29
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PressController = function () {
    function PressController(AppSettings) {
        _classCallCheck(this, PressController);

        cov_xilwg9b9j.f[0]++;
        cov_xilwg9b9j.s[0]++;

        this.AppSettings = AppSettings;
    }

    _createClass(PressController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_xilwg9b9j.f[1]++;
            cov_xilwg9b9j.s[1]++;

            this.items = [
            // 29 05 2018
            {
                image: '../assets/press/publico-29-05-2018.png',
                name: 'Público',
                date: '29.05.2018',
                url: 'https://www.publico.pt/2018/05/29/local/noticia/coliseu-ensaia-entrada-no-novo-circo-com-festival-internacional-1832598'
            }, {
                image: '../assets/press/portopt-29-05-2018.png',
                name: 'Porto.',
                date: '29.05.2018',
                url: 'http://www.porto.pt/noticias/festival-internacional-de-circo-traz-40-espetaculos-e-uma-grande-reflexao-sobre-o-futuro-desta-area-artistica'
            }, {
                image: '../assets/press/dn-29-05-2018.png',
                name: 'Diário de Notícias',
                date: '29.05.2018',
                url: 'https://www.dn.pt/lusa/interior/1o-festival-internacional-de-circo-do-porto-com-40-espetaculos-gratis-entre-13-e-16-de-setembro-9391081.html'
            }, {
                image: '../assets/press/timeout_29_05_2018.png',
                name: 'TimeOut',
                date: '29.05.2018',
                url: ' https://www.timeout.pt/porto/pt/noticias/coliseu-anuncia-o-i-festival-internacional-de-circo-do-porto-052918'
            },
            // 30 05 2018
            {
                image: '../assets/press/jpn-30-05-2018.png',
                name: 'jpn',
                date: '30.05.2018',
                url: 'https://jpn.up.pt/2018/05/30/em-setembro-o-circo-invade-o-porto-com-mais-de-40-espetaculo-gratuitos/'
            }, {
                image: '../assets/press/expresso-30-05-2018.png',
                name: 'Expresso',
                date: '30.05.2018',
                url: 'http://expresso.sapo.pt/cultura/2018-05-30-O-novo-circo-vai-chegar-ao-Coliseu-do-Porto'
            }, {
                image: '../assets/press/jn-30-05-2018.png',
                name: 'Jornal Notícias',
                date: '30.05.2018',
                url: this.AppSettings.appUrl + '/assets/press/jn.pdf'
            },
            // 31 05 2018
            {
                image: '../assets/press/porto_canal-31-05-2018.png',
                name: 'Porto Canal',
                date: '31.05.2018',
                url: 'http://portocanal.sapo.pt/noticia/156683'
            },
            // 05 06 2018
            {
                image: '../assets/press/rr-05-06-2018.png',
                name: 'Rádio Renascença',
                date: '05.06.2018',
                url: 'http://rr.sapo.pt/noticia/114977/o-circo-vai-ao-porto-ao-coliseu-claro-mas-tambem-as-ruas-da-cidade'
            }, {
                image: '../assets/press/jn_online_20-08-2018.png',
                name: 'Jornal Notícias',
                date: '20.08.2018',
                url: 'https://www.jn.pt/local/videos/interior/o-espetaculo-de-circo-que-nasce-no-bonfim-e-faz-palco-no-coliseu-9706284.html'
            }, {
                image: '../assets/press/tsf_31-08-2018.png',
                name: 'TSF',
                date: '31.08.2018',
                url: 'https://www.tsf.pt/cultura/interior/ate-fazem-tremer-as-pedras-da-calcada-9785505.html'
            }, {
                image: '../assets/press/publico_06-09-2018.png',
                name: 'Público',
                date: '06.09.2018',
                url: 'https://www.publico.pt/2018/09/06/local/noticia/jardins-e-pracas-do-porto-recebem-primeiro-festival-internacional-de-circo-1843238'
            }, {
                image: '../assets/press/rtp_14-09-2018.png',
                name: 'RTP',
                date: '14.09.2018',
                url: 'https://www.rtp.pt/noticias/cultura/porto-recebe-festival-internacional-de-circo_v1098870'
            }, {
                image: '../assets/press/publico_16-09-2018.png',
                name: 'Público',
                date: '16.09.2018',
                url: 'https://www.publico.pt/2018/09/16/local/noticia/o-novo-circo-saiu-do-coliseu-para-ocupar-quatro-pracas-do-porto-1844235'
            }];
        }
    }]);

    return PressController;
}();

cov_xilwg9b9j.s[2]++;


angular.module('app').component('press', {
    templateUrl: 'app/components/press/press.html',
    controller: PressController
});