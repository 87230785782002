'use strict';

var cov_2oqhyj6108 = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/index.js',
        hash = 'c682643219a7d553473428365d5453175a53cb85',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 13,
                    column: 19
                }
            },
            '1': {
                start: {
                    line: 17,
                    column: 4
                },
                end: {
                    line: 17,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 19,
                    column: 4
                },
                end: {
                    line: 19,
                    column: 32
                }
            },
            '3': {
                start: {
                    line: 21,
                    column: 4
                },
                end: {
                    line: 21,
                    column: 27
                }
            },
            '4': {
                start: {
                    line: 23,
                    column: 4
                },
                end: {
                    line: 23,
                    column: 31
                }
            },
            '5': {
                start: {
                    line: 24,
                    column: 4
                },
                end: {
                    line: 24,
                    column: 33
                }
            },
            '6': {
                start: {
                    line: 26,
                    column: 4
                },
                end: {
                    line: 26,
                    column: 53
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 30
                },
                end: {
                    line: 26,
                    column: 52
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 19
                },
                end: {
                    line: 34,
                    column: 5
                }
            },
            '9': {
                start: {
                    line: 36,
                    column: 14
                },
                end: {
                    line: 36,
                    column: 57
                }
            },
            '10': {
                start: {
                    line: 38,
                    column: 4
                },
                end: {
                    line: 38,
                    column: 33
                }
            },
            '11': {
                start: {
                    line: 40,
                    column: 4
                },
                end: {
                    line: 40,
                    column: 55
                }
            },
            '12': {
                start: {
                    line: 42,
                    column: 4
                },
                end: {
                    line: 49,
                    column: 6
                }
            },
            '13': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 9
                }
            },
            '14': {
                start: {
                    line: 44,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 35
                }
            },
            '15': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 35
                }
            },
            '16': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 59
                }
            },
            '17': {
                start: {
                    line: 51,
                    column: 4
                },
                end: {
                    line: 112,
                    column: 7
                }
            },
            '18': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 38
                }
            },
            '19': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 31
                }
            },
            '20': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 36
                }
            },
            '21': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 38
                }
            },
            '22': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '23': {
                start: {
                    line: 58,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 59
                }
            },
            '24': {
                start: {
                    line: 59,
                    column: 12
                },
                end: {
                    line: 59,
                    column: 47
                }
            },
            '25': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 55
                }
            },
            '26': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 61,
                    column: 42
                }
            },
            '27': {
                start: {
                    line: 62,
                    column: 12
                },
                end: {
                    line: 62,
                    column: 41
                }
            },
            '28': {
                start: {
                    line: 63,
                    column: 12
                },
                end: {
                    line: 63,
                    column: 39
                }
            },
            '29': {
                start: {
                    line: 64,
                    column: 12
                },
                end: {
                    line: 64,
                    column: 38
                }
            },
            '30': {
                start: {
                    line: 65,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '31': {
                start: {
                    line: 66,
                    column: 12
                },
                end: {
                    line: 66,
                    column: 54
                }
            },
            '32': {
                start: {
                    line: 67,
                    column: 12
                },
                end: {
                    line: 67,
                    column: 53
                }
            },
            '33': {
                start: {
                    line: 68,
                    column: 12
                },
                end: {
                    line: 68,
                    column: 47
                }
            },
            '34': {
                start: {
                    line: 69,
                    column: 12
                },
                end: {
                    line: 69,
                    column: 42
                }
            },
            '35': {
                start: {
                    line: 70,
                    column: 12
                },
                end: {
                    line: 70,
                    column: 38
                }
            },
            '36': {
                start: {
                    line: 71,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '37': {
                start: {
                    line: 72,
                    column: 12
                },
                end: {
                    line: 72,
                    column: 55
                }
            },
            '38': {
                start: {
                    line: 73,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 53
                }
            },
            '39': {
                start: {
                    line: 74,
                    column: 12
                },
                end: {
                    line: 74,
                    column: 47
                }
            },
            '40': {
                start: {
                    line: 75,
                    column: 12
                },
                end: {
                    line: 75,
                    column: 42
                }
            },
            '41': {
                start: {
                    line: 76,
                    column: 12
                },
                end: {
                    line: 76,
                    column: 44
                }
            },
            '42': {
                start: {
                    line: 77,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '43': {
                start: {
                    line: 78,
                    column: 12
                },
                end: {
                    line: 78,
                    column: 55
                }
            },
            '44': {
                start: {
                    line: 79,
                    column: 12
                },
                end: {
                    line: 79,
                    column: 53
                }
            },
            '45': {
                start: {
                    line: 80,
                    column: 12
                },
                end: {
                    line: 80,
                    column: 47
                }
            },
            '46': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 81,
                    column: 42
                }
            },
            '47': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 46
                }
            },
            '48': {
                start: {
                    line: 83,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '49': {
                start: {
                    line: 84,
                    column: 12
                },
                end: {
                    line: 84,
                    column: 55
                }
            },
            '50': {
                start: {
                    line: 85,
                    column: 12
                },
                end: {
                    line: 85,
                    column: 53
                }
            },
            '51': {
                start: {
                    line: 86,
                    column: 12
                },
                end: {
                    line: 86,
                    column: 47
                }
            },
            '52': {
                start: {
                    line: 87,
                    column: 12
                },
                end: {
                    line: 87,
                    column: 41
                }
            },
            '53': {
                start: {
                    line: 88,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '54': {
                start: {
                    line: 89,
                    column: 12
                },
                end: {
                    line: 89,
                    column: 55
                }
            },
            '55': {
                start: {
                    line: 90,
                    column: 12
                },
                end: {
                    line: 90,
                    column: 53
                }
            },
            '56': {
                start: {
                    line: 91,
                    column: 12
                },
                end: {
                    line: 91,
                    column: 47
                }
            },
            '57': {
                start: {
                    line: 92,
                    column: 12
                },
                end: {
                    line: 92,
                    column: 42
                }
            },
            '58': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 42
                }
            },
            '59': {
                start: {
                    line: 94,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '60': {
                start: {
                    line: 95,
                    column: 12
                },
                end: {
                    line: 95,
                    column: 55
                }
            },
            '61': {
                start: {
                    line: 96,
                    column: 12
                },
                end: {
                    line: 96,
                    column: 53
                }
            },
            '62': {
                start: {
                    line: 97,
                    column: 12
                },
                end: {
                    line: 97,
                    column: 47
                }
            },
            '63': {
                start: {
                    line: 98,
                    column: 12
                },
                end: {
                    line: 98,
                    column: 42
                }
            },
            '64': {
                start: {
                    line: 99,
                    column: 12
                },
                end: {
                    line: 99,
                    column: 43
                }
            },
            '65': {
                start: {
                    line: 100,
                    column: 15
                },
                end: {
                    line: 111,
                    column: 9
                }
            },
            '66': {
                start: {
                    line: 101,
                    column: 12
                },
                end: {
                    line: 101,
                    column: 55
                }
            },
            '67': {
                start: {
                    line: 102,
                    column: 12
                },
                end: {
                    line: 102,
                    column: 53
                }
            },
            '68': {
                start: {
                    line: 103,
                    column: 12
                },
                end: {
                    line: 103,
                    column: 47
                }
            },
            '69': {
                start: {
                    line: 104,
                    column: 12
                },
                end: {
                    line: 104,
                    column: 42
                }
            },
            '70': {
                start: {
                    line: 105,
                    column: 12
                },
                end: {
                    line: 105,
                    column: 45
                }
            },
            '71': {
                start: {
                    line: 107,
                    column: 12
                },
                end: {
                    line: 107,
                    column: 53
                }
            },
            '72': {
                start: {
                    line: 108,
                    column: 12
                },
                end: {
                    line: 108,
                    column: 47
                }
            },
            '73': {
                start: {
                    line: 109,
                    column: 12
                },
                end: {
                    line: 109,
                    column: 55
                }
            },
            '74': {
                start: {
                    line: 110,
                    column: 12
                },
                end: {
                    line: 110,
                    column: 42
                }
            }
        },
        fnMap: {
            '0': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 16,
                        column: 9
                    },
                    end: {
                        line: 16,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 96
                    },
                    end: {
                        line: 113,
                        column: 1
                    }
                },
                line: 16
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 26,
                        column: 24
                    },
                    end: {
                        line: 26,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 30
                    },
                    end: {
                        line: 26,
                        column: 52
                    }
                },
                line: 26
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 42,
                        column: 28
                    },
                    end: {
                        line: 42,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 34
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 42
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 51,
                        column: 29
                    },
                    end: {
                        line: 51,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 52
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                line: 51
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }, {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }],
                line: 43
            },
            '1': {
                loc: {
                    start: {
                        line: 57,
                        column: 8
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 57,
                        column: 8
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 57,
                        column: 8
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 57
            },
            '2': {
                loc: {
                    start: {
                        line: 65,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 65,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 65,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 65
            },
            '3': {
                loc: {
                    start: {
                        line: 71,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 71,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 71,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 71
            },
            '4': {
                loc: {
                    start: {
                        line: 77,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 77,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 77,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 77
            },
            '5': {
                loc: {
                    start: {
                        line: 83,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 83,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 83,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 83
            },
            '6': {
                loc: {
                    start: {
                        line: 88,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 88,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 88,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 88
            },
            '7': {
                loc: {
                    start: {
                        line: 94,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 94,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 94,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 94
            },
            '8': {
                loc: {
                    start: {
                        line: 100,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 100,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }, {
                    start: {
                        line: 100,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                }],
                line: 100
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2oqhyj6108.s[0]++;
angular.module('app', ['ui.router', 'ngMaterial', 'gettext', 'youtube-embed', 'viewhead'])
/* eslint-disable no-undef */
.constant('AppSettings', {
    appUrl: 'https://circo.soloweb.pt'
}).run(runBlock);

/** @ngInject */
function runBlock($rootScope, AppSettings, gettextCatalog, $state, $log, $transitions, $window) {
    cov_2oqhyj6108.f[0]++;
    cov_2oqhyj6108.s[1]++;

    $rootScope.AppSettings = AppSettings;

    cov_2oqhyj6108.s[2]++;
    $rootScope.menuOpen = false;

    cov_2oqhyj6108.s[3]++;
    $rootScope.lang = 'pt';

    cov_2oqhyj6108.s[4]++;
    $rootScope.showFeed = true;
    cov_2oqhyj6108.s[5]++;
    $rootScope.fullHeader = true;

    cov_2oqhyj6108.s[6]++;
    $rootScope.goBack = function () {
        cov_2oqhyj6108.f[1]++;
        cov_2oqhyj6108.s[7]++;
        return $window.history.back();
    };

    var themes = (cov_2oqhyj6108.s[8]++, [{ dark: '#ff9900', light: '#FBC785' }, { dark: '#ff3399', light: '#FFF5FB' }, { dark: '#993399', light: '#FAF5FB' }, { dark: '#00cccc', light: '#F3FBFE' }, { dark: '#0066cc', light: '#F3F8FC' }]);

    var i = (cov_2oqhyj6108.s[9]++, Math.floor(Math.random() * themes.length));

    cov_2oqhyj6108.s[10]++;
    $rootScope.theme = themes[i];

    cov_2oqhyj6108.s[11]++;
    gettextCatalog.setCurrentLanguage($rootScope.lang);

    cov_2oqhyj6108.s[12]++;
    $rootScope.changeLang = function () {
        cov_2oqhyj6108.f[2]++;
        cov_2oqhyj6108.s[13]++;

        if ($rootScope.lang == 'en') {
            cov_2oqhyj6108.b[0][0]++;
            cov_2oqhyj6108.s[14]++;

            $rootScope.lang = 'pt';
        } else {
            cov_2oqhyj6108.b[0][1]++;
            cov_2oqhyj6108.s[15]++;

            $rootScope.lang = 'en';
        }
        cov_2oqhyj6108.s[16]++;
        gettextCatalog.setCurrentLanguage($rootScope.lang);
    };

    cov_2oqhyj6108.s[17]++;
    $transitions.onEnter({}, function (transition, state) {
        cov_2oqhyj6108.f[3]++;
        cov_2oqhyj6108.s[18]++;

        $rootScope.fullHeader = false;
        cov_2oqhyj6108.s[19]++;
        $rootScope.filter = {};
        cov_2oqhyj6108.s[20]++;
        $rootScope.menuOpen = false;
        cov_2oqhyj6108.s[21]++;
        $rootScope.hideScroll = false;

        cov_2oqhyj6108.s[22]++;
        if (state.name === 'home') {
            cov_2oqhyj6108.b[1][0]++;
            cov_2oqhyj6108.s[23]++;

            $rootScope.menuBackgroundColor = 'transparent';
            cov_2oqhyj6108.s[24]++;
            $rootScope.menuTextColor = 'white';
            cov_2oqhyj6108.s[25]++;
            $rootScope.showSecondaryNavigation = false;
            cov_2oqhyj6108.s[26]++;
            $rootScope.backButton = false;
            cov_2oqhyj6108.s[27]++;
            $rootScope.fullHeader = true;
            cov_2oqhyj6108.s[28]++;
            $rootScope.showFeed = true;
            cov_2oqhyj6108.s[29]++;
            $rootScope.section = null;
        } else {
                cov_2oqhyj6108.b[1][1]++;
                cov_2oqhyj6108.s[30]++;
                if (state.name === 'program') {
                    cov_2oqhyj6108.b[2][0]++;
                    cov_2oqhyj6108.s[31]++;

                    $rootScope.showSecondaryNavigation = true;
                    cov_2oqhyj6108.s[32]++;
                    $rootScope.menuBackgroundColor = 'white';
                    cov_2oqhyj6108.s[33]++;
                    $rootScope.menuTextColor = 'black';
                    cov_2oqhyj6108.s[34]++;
                    $rootScope.backButton = false;
                    cov_2oqhyj6108.s[35]++;
                    $rootScope.section = null;
                } else {
                        cov_2oqhyj6108.b[2][1]++;
                        cov_2oqhyj6108.s[36]++;
                        if (state.name === 'press') {
                            cov_2oqhyj6108.b[3][0]++;
                            cov_2oqhyj6108.s[37]++;

                            $rootScope.showSecondaryNavigation = false;
                            cov_2oqhyj6108.s[38]++;
                            $rootScope.menuBackgroundColor = 'white';
                            cov_2oqhyj6108.s[39]++;
                            $rootScope.menuTextColor = 'black';
                            cov_2oqhyj6108.s[40]++;
                            $rootScope.backButton = false;
                            cov_2oqhyj6108.s[41]++;
                            $rootScope.section = 'imprensa';
                        } else {
                                cov_2oqhyj6108.b[3][1]++;
                                cov_2oqhyj6108.s[42]++;
                                if (state.name === 'companies') {
                                    cov_2oqhyj6108.b[4][0]++;
                                    cov_2oqhyj6108.s[43]++;

                                    $rootScope.showSecondaryNavigation = false;
                                    cov_2oqhyj6108.s[44]++;
                                    $rootScope.menuBackgroundColor = 'white';
                                    cov_2oqhyj6108.s[45]++;
                                    $rootScope.menuTextColor = 'black';
                                    cov_2oqhyj6108.s[46]++;
                                    $rootScope.backButton = false;
                                    cov_2oqhyj6108.s[47]++;
                                    $rootScope.section = 'companhias';
                                } else {
                                        cov_2oqhyj6108.b[4][1]++;
                                        cov_2oqhyj6108.s[48]++;
                                        if (state.name === 'company') {
                                            cov_2oqhyj6108.b[5][0]++;
                                            cov_2oqhyj6108.s[49]++;

                                            $rootScope.showSecondaryNavigation = false;
                                            cov_2oqhyj6108.s[50]++;
                                            $rootScope.menuBackgroundColor = 'white';
                                            cov_2oqhyj6108.s[51]++;
                                            $rootScope.menuTextColor = 'black';
                                            cov_2oqhyj6108.s[52]++;
                                            $rootScope.backButton = true;
                                        } else {
                                                cov_2oqhyj6108.b[5][1]++;
                                                cov_2oqhyj6108.s[53]++;
                                                if (state.name === 'places') {
                                                    cov_2oqhyj6108.b[6][0]++;
                                                    cov_2oqhyj6108.s[54]++;

                                                    $rootScope.showSecondaryNavigation = false;
                                                    cov_2oqhyj6108.s[55]++;
                                                    $rootScope.menuBackgroundColor = 'white';
                                                    cov_2oqhyj6108.s[56]++;
                                                    $rootScope.menuTextColor = 'black';
                                                    cov_2oqhyj6108.s[57]++;
                                                    $rootScope.backButton = false;
                                                    cov_2oqhyj6108.s[58]++;
                                                    $rootScope.section = 'locais';
                                                } else {
                                                        cov_2oqhyj6108.b[6][1]++;
                                                        cov_2oqhyj6108.s[59]++;
                                                        if (state.name === 'project') {
                                                            cov_2oqhyj6108.b[7][0]++;
                                                            cov_2oqhyj6108.s[60]++;

                                                            $rootScope.showSecondaryNavigation = false;
                                                            cov_2oqhyj6108.s[61]++;
                                                            $rootScope.menuBackgroundColor = 'white';
                                                            cov_2oqhyj6108.s[62]++;
                                                            $rootScope.menuTextColor = 'black';
                                                            cov_2oqhyj6108.s[63]++;
                                                            $rootScope.backButton = false;
                                                            cov_2oqhyj6108.s[64]++;
                                                            $rootScope.section = 'projeto';
                                                        } else {
                                                                cov_2oqhyj6108.b[7][1]++;
                                                                cov_2oqhyj6108.s[65]++;
                                                                if (state.name === 'contacts') {
                                                                    cov_2oqhyj6108.b[8][0]++;
                                                                    cov_2oqhyj6108.s[66]++;

                                                                    $rootScope.showSecondaryNavigation = false;
                                                                    cov_2oqhyj6108.s[67]++;
                                                                    $rootScope.menuBackgroundColor = 'white';
                                                                    cov_2oqhyj6108.s[68]++;
                                                                    $rootScope.menuTextColor = 'black';
                                                                    cov_2oqhyj6108.s[69]++;
                                                                    $rootScope.backButton = false;
                                                                    cov_2oqhyj6108.s[70]++;
                                                                    $rootScope.section = 'contactos';
                                                                } else {
                                                                    cov_2oqhyj6108.b[8][1]++;
                                                                    cov_2oqhyj6108.s[71]++;

                                                                    $rootScope.menuBackgroundColor = 'white';
                                                                    cov_2oqhyj6108.s[72]++;
                                                                    $rootScope.menuTextColor = 'black';
                                                                    cov_2oqhyj6108.s[73]++;
                                                                    $rootScope.showSecondaryNavigation = false;
                                                                    cov_2oqhyj6108.s[74]++;
                                                                    $rootScope.backButton = false;
                                                                }
                                                            }
                                                    }
                                            }
                                    }
                            }
                    }
            }
    });
}