'use strict';

var cov_25g20is1hn = function () {
    var path = '/home/lobo/repos/festival-circo-porto/src/app/shared/htmlToText.filter.js',
        hash = '87e31b6d8e3a320b97e34bfb24fb30f504480368',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/festival-circo-porto/src/app/shared/htmlToText.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 14,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 69
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 14,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'htmlToText',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 26
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 31
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 19
                    },
                    end: {
                        line: 11,
                        column: 68
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 11,
                        column: 26
                    },
                    end: {
                        line: 11,
                        column: 63
                    }
                }, {
                    start: {
                        line: 11,
                        column: 66
                    },
                    end: {
                        line: 11,
                        column: 68
                    }
                }],
                line: 11
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_25g20is1hn.s[0]++;
(function () {
    'use strict';

    cov_25g20is1hn.f[0]++;
    cov_25g20is1hn.s[1]++;
    angular.module('app').filter('htmlToText', htmlToText);

    /* @ngInject */
    function htmlToText() {
        cov_25g20is1hn.f[1]++;
        cov_25g20is1hn.s[2]++;

        return function (text) {
            cov_25g20is1hn.f[2]++;
            cov_25g20is1hn.s[3]++;

            return text ? (cov_25g20is1hn.b[0][0]++, String(text).replace(/<[^>]+>/gm, '')) : (cov_25g20is1hn.b[0][1]++, '');
        };
    }
})();